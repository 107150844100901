<template>
    <div>
        <b-row>
            <b-col>
                <!-- firewall title -->
                <b-form-group
                    id="fieldset-title"
                    :label="translate('COLUMNS.TITLE')"
                    label-for="title"
                >
                    <b-form-input id="title"
                                  v-model="value.title"
                                  placeholder="Titel"
                                  autofocus
                                  name="title"
                    />
                </b-form-group>
            </b-col>
        </b-row>

        <b-tabs v-model="tabIndex"
                fill
                pills
        >
            <hr>
            <b-tab :title="translate('RULE')">
                <form @submit.prevent="$emit('submit')">
                    <b-row cols="12">
                        <b-col cols="12"
                               lg="6"
                               sm="12"
                        >
                            <label> {{ translate('COLUMNS.DIRECTION') }} </label>
                            <b-form-select v-model="value.direction" :options="translatedOptions['directions']" />
                        </b-col>

                        <b-col cols="12"
                               lg="6"
                               sm="12"
                        >
                            <label> {{ translate('COLUMNS.ACTION') }} </label>
                            <b-form-select v-model="value.action" :options="translatedOptions['actions']" />
                        </b-col>

                        <b-col cols="12"
                               lg="6"
                               sm="12"
                        >
                            <label> {{ translate('COLUMNS.INTERFACE') }} </label>
                            <b-form-select v-model="value.interface" :options="translatedOptions['interfaces']" />
                        </b-col>

                        <b-col cols="12"
                               lg="6"
                               sm="12"
                        >
                            <label> {{ translate('COLUMNS.PROTOCOL') }} </label>
                            <b-form-select v-model="value.protocol" :options="translatedOptions['protocols']" />
                        </b-col>

                        <b-col cols="12"
                               sm="12"
                               lg="6"
                        >
                            <b-form-radio-group
                                v-model="useSourceAddressSet"
                                buttons
                                button-variant="outline-primary"
                                size="sm"
                                name="radio-btn-outline"
                                :options="translatedOptions['source_address']"
                                class="mb-5 mt-0 d-flex"
                                @change="value.source_address = null; value.source_address_set_id = null;"
                            />

                            <b-input v-if="useSourceAddressSet === 'SOURCE_ADDRESS'"
                                     v-model="value.source_address"
                                     :placeholder="translate('COLUMNS.SOURCE_ADDRESS')"
                            />

                            <b-form-select v-else
                                           v-model="value.source_address_set_id"
                                           :options="addressOptions"
                            />
                        </b-col>

                        <b-col cols="12"
                               sm="12"
                               lg="6"
                        >
                            <b-form-radio-group
                                v-model="useDestinationAddressSet"
                                buttons
                                button-variant="outline-primary"
                                size="sm"
                                name="radio-btn-outline"
                                :options="translatedOptions['destination_address']"
                                class="mb-5 mt-0 d-flex"
                                @change="value.destination_address = null; value.destination_address_set_id = null;"
                            />

                            <b-input v-if="useDestinationAddressSet === 'DESTINATION_ADDRESS'"
                                     v-model="value.destination_address"
                                     :placeholder="$t('DATACENTER.VSERVERS.DETAILS.FIREWALL.COLUMNS.DESTINATION_ADDRESS')"
                            />

                            <b-form-select v-else
                                           v-model="value.destination_address_set_id"
                                           :options="addressOptions"
                            />
                        </b-col>

                        <b-col cols="12"
                               sm="6"
                               lg="12"
                        >
                            <label> {{ translate('COLUMNS.SOURCE_PORT') }} </label>
                            <b-input v-model="value.source_port" placeholder="Port" />
                        </b-col>

                        <b-col cols="12"
                               sm="6"
                               lg="12"
                        >
                            <label> {{ translate('COLUMNS.DESTINATION_PORT') }} </label>
                            <b-input v-model="value.destination_port" placeholder="Port" />
                        </b-col>
                    </b-row>

                    <div class="float-left mt-6">
                        <b-button
                            variant="primary"
                            type="submit"
                            size="lg"
                            :disabled="$wait.is('firewall-create')"
                        >
                            <i v-if="$wait.is('firewall-create')"
                               class="fa fa-spinner fa-spin"
                            />

                            {{ editing ? $t('DATACENTER.VSERVERS.DETAILS.FIREWALL.CONFIRM_EDIT') : $t('DATACENTER.VSERVERS.DETAILS.FIREWALL.CONFIRM_CREATE') }}
                        </b-button>
                    </div>
                </form>
            </b-tab>

            <b-tab :title="translate('RULESET')">
                <form @submit.prevent="$emit('linkRuleset', ruleset)">
                    <b-row>
                        <b-col>
                            <label>{{ $t('DATACENTER.VSERVERS.DETAILS.FIREWALL.RULESET') }}</label>
                            <b-form-select v-model="ruleset"
                                           :options="ruleOptions"
                            />
                        </b-col>
                    </b-row>

                    <div class="float-left mt-6">
                        <b-button
                            variant="primary"
                            type="submit"
                            size="lg"
                            :disabled="$wait.is('firewall-ruleset-link')"
                        >
                            <i v-if="$wait.is('firewall-ruleset-link')"
                               class="fa fa-spinner fa-spin"
                            />

                            {{ editing ? $t('DATACENTER.VSERVERS.DETAILS.FIREWALL.CONFIRM_RULESET_EDIT') : $t('DATACENTER.VSERVERS.DETAILS.FIREWALL.CONFIRM_RULESET') }}
                        </b-button>
                    </div>
                </form>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import { FirewallAddressSet } from '@/core/services/store/models/firewall_address_set'
import { FirewallRuleSet } from '@/core/services/store/models/firewall_rule_set'
import translationMixin from '@/core/mixins/translationMixin'

export default {
    name: 'CloudServerFirewallConfiguration',
    mixins: [translationMixin],

    props: {
        /*
         * @description: fire-wall rule to edit (v-model)
         * @type: {Object}
         * @default: () => ({})
         * required: false
         */
        value: {
            type: Object,
            default: () => ({}),
            required: false
        }
    },

    data: () => ({
        /*
         * @translationPath: pre-i18n-string for translation
         * @options: fire-wall options
         */
        translationPath: 'DATACENTER.VSERVERS.DETAILS.FIREWALL',
        useSourceAddressSet: 'SOURCE_ADDRESS',
        useDestinationAddressSet: 'DESTINATION_ADDRESS',
        ruleset: 0,
        tabIndex: 0,
        options: {
            directions: ['IN', 'OUT'],
            protocols: ['tcp', 'udp'],
            interfaces: ['net0', 'net1', 'net2', 'net3'],
            actions: ['ACCEPT', 'DROP', 'REJECT'],
            source_address: ['SOURCE_ADDRESS', 'SOURCE_ADDRESS_SET'],
            destination_address: ['DESTINATION_ADDRESS', 'DESTINATION_ADDRESS_SET']
        }
    }),

    computed: {
        /*
         * @description: fire-wall options with i18n strings
         * @returns: translated-options[*]
         */
        translatedOptions () {
            const DO_NOT_TRANSLATE = [ 'protocols', 'interfaces' ]
            return Object.keys(this.options)
                .reduce((acc, el) => ({
                    ...acc,
                    [el]: this.options[el]
                        .map(value => ({
                            value,
                            text: DO_NOT_TRANSLATE.includes(el)
                                ? value?.toUpperCase()
                                : this.translate(`${el?.toUpperCase()}.${value?.toUpperCase()}`)
                        }))

                }), {})
        },

        /*
         * @description: description
         * @returns: options['addressSets']
         */
        addressOptions () {
            const firewallAddressSets = FirewallAddressSet.all()
            const defaultAddress = {
                value: null,
                text: this.$t('DATACENTER.VSERVERS.DETAILS.FIREWALL.ADDRESS_SET'),
                disabled: true
            }

            return [
                { ...defaultAddress },
                ...firewallAddressSets
                    .map(el => ({ value: el.id, text: el.title }))
            ]
        },

        ruleOptions () {
            return FirewallRuleSet.all()
                .map(el => ({ value: el.id, text: el.title }))
        }
    }
}
</script>

<style scoped>
.form-group {
  margin-bottom: 0;
}
</style>
