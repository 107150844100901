<template>
    <div>
        <!-- primary action -->
        <luma-button
            v-if="rules.length"
            v-b-modal="'VserverFirewallCreateModal'"
            title="Regel hinzufügen"
            variant="primary"
            class="mt-6 mb-3"
        />

        <div v-else class="text-center text-muted mt-40">
            <span class="font-weight-bold">
                Der Cloudserver hat momentan noch keine Firewall Regeln.
            </span>

            <div>
                <!-- primary action -->
                <luma-button v-b-modal="'VserverFirewallCreateModal'"
                             title="Regel hinzufügen"
                             variant="primary"
                             class="mt-6 mb-3"
                />
            </div>
        </div>

        <!-- table -->
        <table v-if="rules.length" class="table table-striped table-responsive-md">
            <thead>
                <tr>
                    <th v-for="{ label, key } in mappedFields"
                        :key="key"
                        :class="{'text-center': key === 'actions'}"
                    >
                        {{ label }}
                    </th>
                </tr>
            </thead>

            <!-- draggable component: https://github.com/SortableJS/Vue.Draggable -->
            <draggable v-model="rules"
                       tag="tbody"
                       v-bind="dragOptions"
                       @start="dragging = true"
                       @ende="dragging = false"
                       @change="changed"
            >
                <tr v-for="item in rules"
                    :key="item"
                    class="rules"
                    @click="tableRowClicked(item)"
                >
                    <td v-for="({ key }) in mappedFields" :key="key">
                        {{ item[key] }}
                        <i v-if="key === 'actions'" class="fa fas fa-bars d-block text-center" />
                    </td>
                </tr>
            </draggable>
        </table>

        <!-- Todo: @Pascal Becker -> combine edit & create -->
        <cloud-server-firewall-edit-modal v-if="selectedRule" :server="server" :rule="selectedRule" />
        <cloud-server-firewall-create-modal :server="server" @onCreate="rule => rules.push(rule)" />
    </div>
</template>

<script>
import CloudServerFirewallEditModal from '@/view/components/cloudserver/modals/CloudServerFirewallEditModal'
import CloudServerFirewallCreateModal from '@/view/components/cloudserver/modals/CloudServerFirewallCreateModal'
import draggable from 'vuedraggable'
import LumaButton from '@/view/components/helper/LumaButton'
import { CloudServer } from '@/core/services/store/models/cloud_server'
import api from '@/core/services/openapi.service'

export default {
    name: 'CloudServerFirewall',

    components: {
        LumaButton,
        CloudServerFirewallCreateModal,
        CloudServerFirewallEditModal,
        draggable
    },

    props: {
        server: {
            type: CloudServer,
            required: true
        }
    },

    data: () => ({
        fields: [
            'title',
            'direction',
            'action',
            'interface',
            'source_address',
            'destination_address',
            'protocol',
            'actions'
        ],
        modals: {
            create: false,
            edit: false
        },
        rules: [],
        selectedRule: null,
        dragging: false
    }),

    computed: {
        /*
         * @description: options for drag and drop
         */
        dragOptions () {
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost'
            }
        },

        mappedFields () {
            return this.fields.map(el => ({
                key: el,
                label: this.$t(`DATACENTER.VSERVERS.DETAILS.FIREWALL.COLUMNS.${el.toUpperCase()}`)
            }))
        }
    },

    // Todo: @Pascal Becker -> implement remove & edit functions
    methods: {
        /*
         * @description: change event for <draggable>
         * @params: {moved: {*}, removed: {*}, added: {*}}
         * @docs: https://github.com/SortableJS/Vue.Draggable
         */
        async changed ({ moved }) {
            if (!moved) return

            await api.updateCloudServerFirewallRule(this.server.id, moved.element?.id,
                { order: moved.newIndex }
            )

            await this.fetchRules()
        },

        /*
         * @description: clicked table row and data props
         * @params: {*}
         */
        tableRowClicked (row) {
            console.log(row)
        },

        /*
         * @description: fetch firewall rules for {cloudServer}
         */
        async fetchRules () {
            const response = await api.getCloudServerFirewallRules(this.server.id)
            this.rules = response.data
        }
    },

    async created () {
        await this.fetchRules()
    }
}
</script>

<style scoped lang="scss">
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }

  .rules {
    cursor: pointer;
  }

  .fa-bars {
    cursor: move;
  }
</style>
