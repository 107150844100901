<template>
    <div class="row">
        <div class="col-sm pb-0 pt-2">
            <h6>
                {{ title }}
            </h6>
        </div>
        <div class="col-sm-12 pt-2">
            <b-input
                :value="value"
                type="range"
                :min="min"
                :max="max"
                :step="step"
                :style="'--min:'+min +';--max:'+ max + ';--val:'+ value"
                tabindex="-1"
                v-on="$listeners"
                @change="v => $emit('input', parseFloat(v))"
            />
            <div class="d-flex justify-content-between">
                <div class="align-self-start font-size-sm">
                    {{ formatValues(min, name) }}
                </div>
                <div class="align-self-center font-size-sm font-weight-bold">
                    {{ formatValues(value, name) }}
                </div>
                <div class="align-self-end font-size-sm">
                    {{ formatValues(max, name) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import FormatValues from '@/core/mixins/formatValues'

export default {
    name: 'SliderEdit',
    mixins: [FormatValues],
    props: {
        value: {
            type: null,
            default: 1
        },
        title: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        min: {
            type: Number,
            default: 1
        },
        max: {
            type: Number,
            default: 2
        },
        default: {
            type: Number,
            default: 1
        },
        step: {
            type: Number,
            default: 1
        }
    },
    mounted: (callback, context) => {
        const range = document.querySelector('[type=range]')
        document.documentElement.classList.add('js')

        range.addEventListener('input', e => {
            range.style.setProperty('--val', +range.value)
        }, false)
    }

}
</script>

<style scoped lang="scss">

$track-w: 100%;
$track-h: .5rem;
$thumb-d: 1.5rem;
$track-c: #ccc;
$filll-c: #AC0006;

@mixin track($fill: 0) {
  box-sizing: border-box;
  border: none;
  width: $track-w; height: $track-h;
  background: $track-c;

  @if $fill == 1 {
    .js & {
      background: linear-gradient($filll-c, $filll-c)
      0/ var(--sx) 100% no-repeat $track-c
    }
  }
}

@mixin fill() {
  height: $track-h;
  background: $filll-c
}

@mixin thumb() {
  box-sizing: border-box;
  border: none;
  width: $thumb-d; height: $thumb-d;
  border-radius: 50%;
  background: #AC0006;
}

[type='range'] {
  &, &::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--val) - var(--min))/var(--range));
  --sx: calc(.5*#{$thumb-d} + var(--ratio)*(100% - #{$thumb-d}));
  margin: 0;
  padding: 0;
  width: $track-w; height: $thumb-d;
  background: transparent;
  font: 1em/1 arial, sans-serif;

  &::-webkit-slider-runnable-track {
    @include track(1)
  }
  &::-moz-range-track { @include track }
  &::-ms-track { @include track }

  &::-moz-range-progress { @include fill }
  &::-ms-fill-lower { @include fill }

  &::-webkit-slider-thumb {
    margin-top: .5*($track-h - $thumb-d);
    @include thumb
  }
  &::-moz-range-thumb { @include thumb }
  &::-ms-thumb {
    margin-top: 0;
    @include thumb
  }

  &::-ms-tooltip { display: none }
}

</style>
