const translationMixin = {
    /*
     * @translationPath: pre-String for i18n language key
     */
    data: () => ({
        translationPath: ''
    }),

    /*
     * @description: makes it easier to use long language file keys -> therefore better naming
     * @params {path: String, data: {Object}}
     * @returns i18n {String}
     */
    methods: {
        translate (path, data) {
            return this.$t(`${this.translationPath}.${path}`, data)
        }
    }
}

export default translationMixin
