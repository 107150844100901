<template>
    <div>
        <!-- primary action -->
        <luma-button v-if="tasks.length"
                     title="Aufgabe erstellen"
                     type="submit"
                     :disabled="$wait.waiting('create-backup')"
                     variant="primary"
                     class="mt-6 mb-6"
                     @click="modals.create = true"
        />

        <div v-if="!tasks.length" class="text-center text-muted mt-40">
            <span class="font-weight-bold">
                Der Cloudserver hat momentan noch keine geplanten Aufgaben.
            </span>

            <div>
                <!-- primary action -->
                <luma-button title="Aufgabe erstellen"
                             type="submit"
                             variant="primary"
                             class="mt-6 mb-3"
                             @click="modals.create = true"
                />
            </div>
        </div>

        <b-table
            v-if="tasks.length"
            bordered
            striped
            hover
            responsive="sm"
            :fields="fields"
            :items="tasks"
            class="mb-0"
        >
            <template v-slot:cell(delete)="{item}">
                <b-link @click="deleteTask(item)">
                    Löschen
                </b-link>
            </template>
        </b-table>

        <b-modal v-if="modals.create"
                 id="file-details"
                 v-model="modals.create"
                 size="lg"
                 :hide-footer="true"
                 :hide-header="true"
                 centered
        >
            <CloudServerScheduledTaskCreateModal v-model="localTask"
                                                 @close="modals.create = false"
                                                 @create="createTask"
            />
        </b-modal>
    </div>
</template>

<script>
import LumaButton from '@/view/components/helper/LumaButton'
import { CloudServerScheduledTask } from '@/core/services/store/models/cloudServerScheduledTask'
import { waitFor } from 'vue-wait'
import CloudServerScheduledTaskCreateModal from '@/view/components/cloudserver/modals/CloudServerScheduledTaskCreateModal'

export default {
    name: 'CloudServerScheduledTasks',
    components: { CloudServerScheduledTaskCreateModal, LumaButton },

    data: () => ({
        localTask: new CloudServerScheduledTask({ interval: 'hour', action: 'start' }),
        fields: ['id', 'type', 'next_execution', 'interval', 'delete'],
        modals: { create: false }
    }),

    computed: {
        tasks () {
            return CloudServerScheduledTask.all() || []
        }
    },

    methods: {
        /*
         * @description: Creates a task for the current cloud server
         * @params: data = {type: String, next_execution: Date, interval: Number}
         */
        createTask: waitFor('create-task', async function () {
            await CloudServerScheduledTask.createById(this.$route.params.id, this.localTask)
            this.modals.create = false
        }),

        /*
         * @description: Deletes selected task for the current cloud server
         * @params: {b-table: row}
         */
        deleteTask: waitFor('delete-task', async function ({ id }) {
            await CloudServerScheduledTask.deleteById(this.$route.params.id, id)
        })
    },

    watch: {
        '$route.params.id': {
            handler: async function (id) { await CloudServerScheduledTask.fetchById(id) },
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
