<template>
    <div>
        <h4>{{ $t('DATACENTER.VSERVERS.CONFIGURATION.COMPUTE_POWER') }}</h4>
        <b-row v-if="Object.values(options).length > 0">
            <b-col class="mb-10">
                <div class="card card-custom card-stretch">
                    <div class="card-body pb-3 pt-3">
                        <SliderEdit v-model="configuration.cores"
                                    :step="options.cores.step"
                                    :min="options.cores.min_units"
                                    :max="options.cores.max_units"
                                    name="cores"
                                    :title="$tc(`DATACENTER.VSERVERS.CONFIGURATION.CORES`, 2)"
                        />
                    </div>
                </div>
            </b-col>
            <b-col class="mb-10">
                <div class="card card-custom card-stretch">
                    <div class="card-body pb-3 pt-3">
                        <SliderEdit v-model="configuration.memory"
                                    :step="options.memory.step"
                                    :min="options.memory.min_units"
                                    :max="options.memory.max_units"
                                    name="memory"
                                    :title="$tc(`DATACENTER.VSERVERS.CONFIGURATION.MEMORY`, 2)"
                        />
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-button v-if="configurationChanged" variant="primary" @click="changeConfiguration">
            Übernehmen
        </b-button>
        <hr>
        <h4>Festplatten</h4>
        <b-alert variant="warning" :show="true">
            Dieser Bereich ist noch in Arbeit
        </b-alert>
        <hr>
        <h4>Netzwerk</h4>
        <b-alert variant="warning" :show="true">
            Dieser Bereich ist noch in Arbeit
        </b-alert>
    </div>
</template>

<script>
import { CloudServer } from '@/core/services/store/models/cloud_server'
import projectMixin from '@/core/mixins/projectMixin'
import customerMixin from '@/core/mixins/customerMixin'
import SliderEdit from '@/view/components/helper/SliderEdit'

export default {
    name: 'CloudServerConfiguration',
    components: { SliderEdit },
    mixins: [projectMixin, customerMixin],
    props: {
        server: {
            type: CloudServer,
            required: true
        }
    },
    data() {
        return {
            options: [],
            configuration: {
                cores: 1,
                memory: 1
            }
        }
    },
    computed: {
        configurationChanged() {
            if (!this.server) { return false }
            return this.server.cores !== this.configuration.cores || (this.server.memory / 1024) !== this.configuration.memory
        }
    },
    methods: {
        async loadPricing () {
            const response = await this.api.getCloudServerPricing({ customer_id: this.customer.id, project_uuid: this.project.uuid })

            const memory = response.data.memory
            response.data.memory = {
                ...memory,
                min_units: memory.min_units / 1024,
                max_units: memory.max_units / 1024,
                step: memory.step / 1024
            }

            this.options = response.data
        },
        async changeConfiguration() {
            const answer = await this.$swal({ title: 'Neustart erforderlich', text: 'Sollen die Änderungen wirklich übernommen und der Server neugestartet werden?', showConfirmButton: true, showCancelButton: true, icon: 'warning' })
            if (answer.isConfirmed) {
                const response = await this.api.updateCloudServer(this.server.id, { ...this.configuration, memory: this.configuration.memory * 1024 })
                await CloudServer.insertOrUpdate(response)
                await this.$swal({ title: 'Erfolgreich', text: 'Die Konfiguration wurde geändert', icon: 'success' })
            }
        }
    },
    watch: {
        server(newValue, oldValue) {
            this.configuration.cores = newValue.cores
            this.configuration.memory = newValue.memory / 1024
        },
        project(newValue, oldValue) {
            if (newValue && this.customer) {
                this.loadPricing()
            }
        }
    },
    created() {
        if (this.project) {
            this.loadPricing()
        }
    }
}
</script>

<style scoped>

</style>
