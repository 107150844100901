import { Project } from '@/core/services/store/models/project'

export default {
    computed: {
        project() {
            if (!this.$route.meta.entity) { return null }
            const id = this.$route.params[this.$route.meta.entity.primaryKey]
            const product = this.$route.meta.entity.find(id)
            if (!product) {
                this.$route.meta.entity.fetchSingle(id)
                return null
            }
            if (product instanceof Project) {
                return product
            }
            const project = Project.find(product.project_uuid)
            if (!project) {
                Project.fetchAll()
                return null
            }
            return project
        }
    }
}
