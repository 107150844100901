<template>
    <div>
        <b-modal id="VserverFirewallEditModal"
                 title="Firewall Regel bearbeiten"
                 :hide-footer="true"
                 back
                 size="xl"
        >
            <CloudServerFirewallConfiguration v-model="rule"
                                              :vserver-id="server.id"
                                              :editing="true"
                                              @linkRuleset="onEditLink"
                                              @submit="onEdit()"
            />
        </b-modal>
    </div>
</template>

<script>
import api from '@/core/services/openapi.service'
import CloudServerFirewallConfiguration from '@/view/components/cloudserver/CloudServerFirewallConfiguration'
import { CloudServer } from '@/core/services/store/models/cloud_server'

export default {
    name: 'CloudServerFirewallEditModal',
    components: { CloudServerFirewallConfiguration },
    props: {
        server: {
            type: CloudServer,
            required: true
        },
        rule: {
            type: Object,
            required: true
        }
    },

    methods: {
        async onEdit () {
            this.$wait.start('firewall-create')
            const body = this.rule
            if (body.source_address == null) { delete body.source_address }
            if (body.source_address_set_id == null) { delete body.source_address_set_id }
            if (body.destination_address == null) { delete body.destination_address }
            if (body.destination_address_set_id == null) { delete body.destination_address_set_id }
            if (body.linked_rule_set_id == null) { delete body.linked_rule_set_id }
            await api.updateCloudServerFirewallRule(this.server.id, this.rule.id, this.rule)
            this.$wait.end('firewall-create')
            this.$bvModal.hide('VserverFirewallEditModal')
        },
        async onEditLink (linkId) {
            this.$wait.start('firewall-ruleset-link')

            await api.updateCloudServerFirewallRule(this.server.id, this.rule.id, {
                title: this.rule.title,
                linked_rule_set_id: linkId
            })

            this.$bvModal.hide('VserverFirewallEditModal')
            this.$wait.end('firewall-ruleset-link')
        }
    }
}
</script>

<style scoped>

</style>
