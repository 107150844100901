import { Customer } from '@/core/services/store/models/customer'
import { Project } from '@/core/services/store/models/project'

export default {
    computed: {
        customer () {
            if (this.project) {
                return Customer.find(this.project.customer_id)
            }
            const id = this.$route.params.customer_id
            return Customer.find(id)
        },
        customerProjects() {
            if (this.customer) {
                return Project.query().where('customer_id', this.customer.id).get()
            }
            return []
        }
    }
}
