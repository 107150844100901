<template>
    <b-button v-bind="$attrs"
              v-on="$listeners"
    >
        <i v-if="icon"
           class="fas fa-fw"
           :class="icon"
        />
        {{ title }}
    </b-button>
</template>

<script>
export default {
    name: 'LumaButton',
    props: {
    /*
     * @description: title for button
     * default: ''
     * required: true
     */
        title: {
            type: String,
            default: '',
            required: true
        },

        /*
         * @description: icon for button
         * default: ''
         * required: false
         */
        icon: {
            type: String,
            default: '',
            required: false
        }
    }
}
</script>

<style scoped>

</style>
