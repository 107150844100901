<template>
    <div v-if="!isEditing"
         class="editable"
         @click="isEditing = true"
         @click.stop=""
    >
        <span v-if="value">{{ value }}</span>
        <span v-else>
            <i style="font-size: 14px">{{ placeholder || "Nicht gesetzt" }}</i>
        </span>
        <b-spinner v-show="$wait.is(waitTitle)"
                   class="custom-spinner"
                   size="sm"
        />
    </div>
    <div v-else @click.stop="">
        <b-input
            ref="input"
            v-bind="$attrs"
            :value="newValue"
            :placeholder="placeholder"
            autofocus
            size="sm"
            v-on="$listeners"
            @input="input => this.newValue = input"
            @keydown.enter="handleBlur"
            @keydown.esc="cancelBlur"
            @blur="handleBlur"
        />
    </div>
</template>

<script>
export default {
    name: 'ClickToEdit',
    props: {
        value: {
            type: null,
            required: true
        },
        placeholder: {
            type: String,
            default: ''
        },
        waitTitle: {
            type: String,
            default: ''
        }
    },

    data: () => ({
        isEditing: false,
        original: null,
        newValue: ''
    }),

    methods: {
        cancelBlur () {
            this.value = this.original
            this.newValue = this.original
            this.isEditing = false
            this.$emit('input', this.value)
        },

        async handleBlur () {
            this.isEditing = false
            if (this.newValue === this.original) { return }

            this.original = this.newValue
            this.$emit('input', this.newValue)
            this.$emit('save')
        }
    },

    watch: {
        value () {
            this.original = this.value
            this.newValue = this.value
        }
    },

    created () {
        this.newValue = this.value
        this.original = this.value
    }
}
</script>

<style scoped lang="scss">
    .editable {
        /*
        * because of <i>
        */
        /*padding: 4px 5px 4px 4px;*/
        padding-right: 5px;
        padding-left: 0px;
        padding-top: 4px;
        padding-bottom: 4px;
        position: relative;
        border-radius: 4px;

      cursor: text;

        &:hover {
            padding-left: 4px;
            background-color: #ebecf0;
            transition: all 0.2s ease-in-out;
        }
    }

    .form-control-sm {
        font-size: 14px;
        height: calc(1em + 1rem);
        min-height: 29px;
        border-radius: 4px;
    }

    .spinner-border {
        display: inline-block;
        width: 1rem;
        height: 1rem;
        vertical-align: text-bottom;
        border: .10em solid;
        border-right: .25em solid transparent;
        border-radius: 50%;
        -webkit-animation: spinner-border .75s linear infinite;
        animation: spinner-border .75s linear infinite;
    }

    .custom-spinner {
        position: absolute;
        right: 0px;
        margin: 4px;
    }
</style>

<style>

</style>
