<template>
    <div>
        <div class="d-flex align-items-center justify-content-between">
            <div>
                <h1 class="mb-0">
                    Aufgabe erstellen
                </h1>
            </div>
            <i class="fa fas fa-times-circle h1 mb-0 cursor-pointer" @click="$emit('close')" />
        </div>

        <form @submit.prevent="$emit('create')">
            <b-row class="mt-5">
                <b-col cols="12"
                       lg="6"
                       md="6"
                       sm="12"
                >
                    <label> {{ translate('COLUMNS.ACTION') }} </label>
                    <b-form-select v-model="value.action" :options="translatedOptions['actions']" />
                </b-col>

                <b-col cols="12"
                       lg="6"
                       md="6"
                       sm="12"
                >
                    <label> {{ translate('COLUMNS.INTERVAL') }} </label>
                    <b-form-select v-model="value.interval" :options="translatedOptions['intervals']" />
                </b-col>

                <b-col cols="12"
                       lg="6"
                       md="6"
                       sm="6"
                >
                    <label> {{ translate('COLUMNS.NEXT_EXECUTION') }} </label>
                    <b-form-datepicker v-model="date" locale="de" />
                </b-col>

                <b-col cols="12"
                       lg="6"
                       md="6"
                       sm="6"
                >
                    <label class="invisible">hidden</label>
                    <b-form-timepicker v-model="time" locale="de" />
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="4"
                       offset-lg="8"
                       class="mt-3"
                >
                    <b-button variant="primary"
                              size="lg"
                              block
                              type="submit"
                              :disabled="$wait.waiting('create-task')"
                    >
                        Erstellen
                    </b-button>
                </b-col>
            </b-row>
        </form>
    </div>
</template>

<script>
import translationMixin from '@/core/mixins/translationMixin'
import DateService from '@/core/services/date.service'

export default {
    name: 'CloudServerScheduledTaskCreateModal',
    mixins: [translationMixin],

    props: {
        /*
         * @description: Task to create or edit from parent
         * @type: Object
         * default: () => ({})
         * required: true
         */
        value: {
            type: Object,
            default: () => ({}),
            required: true
        }
    },

    data: () => ({
        translationPath: 'DATACENTER.VSERVERS.DETAILS.TASKS',
        date: null,
        time: null,
        options: {
            actions: ['start', 'stop', 'shutdown', 'restart', 'backup'],
            intervals: ['hour', 'day', 'week', 'month', 'year']
        }
    }),

    computed: {
        translatedOptions () {
            return Object.keys(this.options)
                .reduce((acc, el) => ({
                    ...acc,
                    [el]: this.options[el]
                        .map(value => ({
                            value,
                            text: this.translate(`${el?.toUpperCase()}.${value?.toUpperCase()}`)
                        }))
                }), {})
        }
    },

    watch: {
        date: {
            handler: function (date) {
                this.$emit('input', { ...this.value, next_execution: `${date} ${this.time}` })
            }
        },

        time: {
            handler: function (time) {
                this.$emit('input', { ...this.value, next_execution: `${this.date} ${time}` })
            }
        }
    },

    created () {
        this.date = DateService.getCurrentDate()
        this.time = DateService.getCurrentTime()
    }
}
</script>

<style scoped>

</style>
