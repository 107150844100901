<template>
    <div class="bg-white p-5" style="margin: 30px; border-radius: 10px; min-height: calc(100vh - 300px)">
        <div class="float-right">
            <b-button v-if="(status ? status.status : 'none') !== 'running'" size="sm" @click="startServer">
                Starten
            </b-button>
            <div v-else>
                <b-button size="sm" @click="shutdownServer">
                    Herunterfahren
                </b-button>
                <b-button size="sm" class="ml-1" @click="stopServer">
                    Stoppen
                </b-button>
                <b-button size="sm" class="ml-1" @click="restartServer">
                    Neustarten
                </b-button>
            </div>
        </div>
        <h1>Cloud Server {{ server ? server.id : '' }}</h1>
        <b-tabs class="mt-5">
            <b-tab class="p-3">
                <template #title>
                    <b-icon-play-fill fill="currentColor" />
                    <span class="ml-3">Übersicht</span>
                </template>
                <CloudServerOverview v-if="server" :server="server" :status="status" />
            </b-tab>
            <b-tab class="p-3">
                <template #title>
                    <b-icon-wrench fill="currentColor" />
                    <span class="ml-3">Konfiguration</span>
                </template>
                <CloudServerConfiguration v-if="server" :server="server" />
            </b-tab>
            <b-tab class="p-3">
                <template #title>
                    <b-icon-alarm fill="currentColor" />
                    <span class="ml-3">Aufgaben</span>
                </template>
                <CloudServerScheduledTasks v-if="server" :server="server" />
            </b-tab>
            <b-tab class="p-3">
                <template #title>
                    <b-icon-hdd-network fill="currentColor" />
                    <span class="ml-3">Backups</span>
                </template>
                <CloudServerBackups v-if="server" :server="server" />
            </b-tab>
            <b-tab class="p-3">
                <template #title>
                    <b-icon-bricks fill="currentColor" />
                    <span class="ml-3">Firewall</span>
                </template>

                <CloudServerFirewall v-if="server" :server="server" />
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import { CloudServer } from '@/core/services/store/models/cloud_server'
import CloudServerOverview from './CloudServerOverview'
import CloudServerFirewall from './CloudServerFirewall'
import CloudServerScheduledTasks from './CloudServerScheduledTasks'
import CloudServerBackups from './CloudServerBackups'
import { CloudServerStatus } from '@/core/services/store/models/cloud_server_status'
import CloudServerConfiguration from '@/view/pages/cloudserver/CloudServerConfiguration'

export default {
    name: 'CloudServerSingle',
    components: { CloudServerConfiguration, CloudServerBackups, CloudServerScheduledTasks, CloudServerFirewall, CloudServerOverview },
    data() {
        return {
            statusTimer: null
        }
    },
    computed: {
        server() {
            const server = CloudServer.find(this.$route.params.id)
            if (!server) { CloudServer.fetchSingle(this.$route.params.id) }
            return server
        },
        status() {
            return CloudServerStatus.find(this.server ? this.server.id : 0)
        }
    },
    methods: {
        startServer() {
            this.api.startCloudServer(this.server.id)
        },
        shutdownServer() {
            this.api.shutdownCloudServer(this.server.id)
        },
        stopServer() {
            this.api.stopCloudServer(this.server.id)
        },
        restartServer() {
            this.api.restartCloudServer(this.server.id)
        }
    },
    async created() {
        this.statusTimer = setInterval(CloudServerStatus.fetchAll, 3000)
    },
    beforeDestroy() {
        clearInterval(this.statusTimer)
    }
}
</script>

<style scoped>

</style>
