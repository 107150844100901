<template>
    <div>
        <!-- primary action -->
        <luma-button v-if="backups.length"
                     title="Backup erstellen"
                     type="submit"
                     :disabled="$wait.waiting('create-backup')"
                     variant="primary"
                     class="mt-6 mb-6"
                     @click="createBackup"
        />

        <div v-if="!backups.length" class="text-center text-muted mt-40">
            <span class="font-weight-bold">
                Der Cloudserver hat momentan noch keine Backups.
            </span>

            <div>
                <!-- primary action -->
                <luma-button title="Backup erstellen"
                             type="submit"
                             :disabled="$wait.waiting('create-backup')"
                             variant="primary"
                             class="mt-6 mb-3"
                             @click="createBackup"
                />
            </div>
        </div>

        <b-table
            v-if="backups.length"
            bordered
            striped
            hover
            responsive="sm"
            :fields="fields"
            :items="backups"
            class="mb-0"
        >
            <template v-slot:cell(delete)="{item}">
                <b-link @click="deleteBackup(item)">
                    Löschen
                </b-link>
            </template>

            <template v-slot:cell(restore)="{item}">
                <b-link @click="restoreBackup(item)">
                    Wiederherstellen
                </b-link>
            </template>
        </b-table>
    </div>
</template>

<script>
import LumaButton from '@/view/components/helper/LumaButton'
import { waitFor } from 'vue-wait'
import { Backup } from '@/core/services/store/models/backup'
import { CloudServer } from '@/core/services/store/models/cloud_server'

export default {
    name: 'CloudServerBackups',
    components: { LumaButton },

    props: {
        server: {
            type: CloudServer,
            required: true
        }
    },

    data: () => ({
        fields: ['id', 'title', 'size', 'created_at', 'restore', 'delete']
    }),

    computed: {
        backups () {
            return Backup.all() || []
        }
    },

    methods: {
        createBackup: waitFor('create-backup', async function () {
            await Backup.createById(this.server.id)
        }),

        // gets backup as parameter
        deleteBackup: waitFor('delete-backup', async function ({ id }) {
            await Backup.deleteById(this.server.id, id)
        }),

        // gets backup as parameter
        restoreBackup: waitFor('restore-backup', async function ({ id }) {
            await Backup.restoreById(this.server.id, id)
        })
    }
}
</script>

<style scoped>

</style>
