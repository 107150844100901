export default {
    methods: {
        formatValues (value, type) {
            switch (type) {
            case 'memory':
                if (value % 1024 === 0) { return value / 1024 + ' GB' }

                return value + ' GB'
            case 'cores':
                return value + (value === 1 ? ' Kern' : ' Kerne')
            case 'disk':
                return value + ' GB'
            default:
                return value
            }
        }
    }
}
